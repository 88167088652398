const headerContent = ["Ресторан", "Интерьер", "Меню", "Пивоварня"];
const headerContentN = [
  {
    titel: "Ресторан",
    id: "#rest",
  },
  {
    titel: "Интерьер",
    id: "#inter",
  },
  {
    titel: "Меню",
    id: "#memu",
  },
  {
    titel: "Пивоварня",
    id: "#beer",
  },
];
const dopContact = ["Бронь", "Контакты"];
const dopContactN = [
  {
    titel: "Бронь",
    id: "#rent",
  },
  {
    titel: "Контакты",
    id: "#contact",
  },
];

export { headerContent, dopContact, headerContentN, dopContactN };
