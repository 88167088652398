import styles from "../../../styles/layout/BlockPhilosophy/Philosophy.module.scss";
import { PcPipes } from "../../Widgets/WidgetsPipe/Pipes";
import { discriptPhilosophy, contentBear } from "./dataPhilosophy";

const Philosophy = () => {
  return (
    <section id="rest" className={styles.container}>
      <div className={styles.blockTitle}>
        <h2 className={styles.textTitle}>ПОЗНАКОМЬТЕСЬ С НАШЕЙ ФИЛОСОФИЕЙ</h2>
      </div>
      <div className={styles.contentPhilosophy}>
        <div className={styles.blockDiscript}>
          <p className={styles.textDiscriptPhilosophy}>{discriptPhilosophy}</p>
        </div>
        <div className={styles.contentBlockBear}>
          {contentBear.map((elem, index) => (
            <div key={index} className={styles.blockContentBear}>
              <h2 className={styles.textTitelContentBear}>
                {elem?.titel.toLocaleUpperCase()}
              </h2>
              <span className={styles.textDiscrContentBear}>
                {elem?.discript}
              </span>
            </div>
          ))}
        </div>

        <img
          className={styles.imgCaraftBear}
          src={
            // "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/craftbear998.jpg"
            "/img/Philosophy/image 998.jpg"
          }
          alt={"image 998.jpg"}
        />
      </div>
      <PcPipes />
    </section>
  );
};

export { Philosophy };
