const dataFooter = {
  titelTimeWork: "Время работы:",
  timeWork: "Понедельник — Воскресенье: 12:00 — 00:00",
  titelAdress: "Адрес:",
  adress: "г. Сочи, Россия, Виноградная ул., 284/1",
  titelPhone: "Телфон:",
  phone: "+7 862 552-03-00",
};

export { dataFooter };
