import { useRef } from "react";
import styles from "../../../styles/layout/Widgets/modal/ModalBar.module.scss";
import { dataBar } from "../../Entities/BlockAtmosphere/dataAtmosphere";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Mousewheel,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// icon
import iconCloseWhite from "../../../assets/icon/sliderModal/closewhite.png";
import iconLeftSwipe from "../../../assets/icon/sliderModal/arrow-backwardwhite.png";
import iconRightSwipe from "../../../assets/icon/sliderModal/arrow-forwardwhite.png";

const ModalBar = ({ setOpenModal }) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const swiperRef = useRef();

  return (
    <div className={styles.wrapper}>
      <div
        onClick={() => setOpenModal(() => false)}
        className={styles.buttonClose}
      >
        <img src={iconCloseWhite} />
      </div>
      <div className={styles.blockLeftButton}>
        <button
          ref={navigationPrevRef}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
          }}
          onClick={() => swiperRef.current.slidePrev()}
          //   ref={navigationPrevRef}
        >
          <img src={iconLeftSwipe} />
        </button>
      </div>
      <Swiper
        style={{
          "--swiper-pagination-color": "#E6702D",
          "--swiper-pagination-bullet-inactive-color": "#D9D9D9",
          "--swiper-pagination-bullet-inactive-opacity": "1",
          "--swiper-pagination-bullet-size": "16px",
          "--swiper-pagination-bullet-horizontal-gap": "8px",
          "--swiper-pagination-bottom": "65px",
        }}
        modules={[Navigation, Mousewheel, Pagination]} //Thumbs
        direction="horizontal"
        slidesPerView={1}
        pagination={true}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        // mousewheel={true}
        lazy={true}
        // spaceBetween={24}
        loop={true}
        speed={600}
        className="mySwiper"
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
      >
        {dataBar.map((card, index) => (
          <SwiperSlide key={index}>
            <img className={styles.sliderPhoto} src={card?.photo} />
          </SwiperSlide>
        ))}
      </Swiper>
      {/* </div> */}
      <div className={styles.blockRightButton}>
        <button
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
          }}
          ref={navigationNextRef}
          onClick={() => swiperRef.current.slideNext()}
        >
          <img src={iconRightSwipe} />
        </button>
      </div>
    </div>
  );
};

export { ModalBar };
