const discript =
  "Мы аккуратно сочетаем традиционную кухню Красной Поляны, баварский подход к закускам и личные кулинарные находки";

const dataDiscriptPhoto = [
  {
    photo:
      // "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/food/rectangle_83.jpg",
      "/img/AboutFood/image 1096.png",
    titel: "Филе утки с брусничным соусом",
    discript:
      "Нежное утки филе с ягодным соусом и гарниром из обжаренных овощей.",
  },

  {
    photo: "/img/AboutFood/image 1109.png",
    titel: "Филе утки с брусничным соусом",
    discript:
      "Нежное утки филе с ягодным соусом и гарниром из обжаренных овощей",
  },
  {
    photo: "/img/AboutFood/image 1097.png",
    titel: "Филе утки с брусничным соусом",
    discript:
      "Нежное утки филе с ягодным соусом и гарниром из обжаренных овощей.",
  },
  {
    photo: "/img/AboutFood/image 1103.png",
    titel: "Филе утки с брусничным соусом",
    discript:
      "Нежное утки филе с ягодным соусом и гарниром из обжаренных овощей",
  },
  {
    photo: "/img/AboutFood/image 1099.png",
    titel: "Филе утки с брусничным соусом",
    discript:
      "Нежное утки филе с ягодным соусом и гарниром из обжаренных овощей",
  },
];
export { discript, dataDiscriptPhoto };
