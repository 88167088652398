const dataSortBeer = [
  {
    titel: "Пшеничное",
    photo: "/img/ByTryingBeer/beer/Group_1171275853.png",
    titelRed: "«Шальная московка»",
    discript: "Классическое, плотное, со сладковатой основой и солодовым телом",
    music: "",
  },
  {
    titel: "Портер",
    photo: "/img/ByTryingBeer/beer/Group_1171275854.png",

    titelRed: "«Бравый ворон»",
    discript:
      "Насыщенный, глубокий и сложный вкус с оттенками карамели и сухофруктов",
    music: "",
  },
  {
    titel: "Ипа",
    photo: "/img/ByTryingBeer/beer/Group_1171275856.png",

    titelRed: "«Капитан воробей»",
    discript:
      "Крепкое, с выраженной горчинкой, характерным цветочным и фруктовым вкусом",
    music: "",
  },
  {
    titel: "Лагер",
    photo: "/img/ByTryingBeer/beer/Group_1171275858.png",

    titelRed: "«Хмельной дрозд»",
    discript: "Классическое, плотное, со сладковатой основой и солодовым телом",
    music: "",
  },
];

export { dataSortBeer };
