import styles from "../../../styles/layout/Widgets/WidgetsPipe/Pipes.module.scss";

const BlockTheTank = () => {
  return (
    <div className={styles.blockTheTank}>
      <img
        src={
          "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/mask_group.png"
        }
      />
    </div>
  );
};

const BlockTheTankSecond = () => {
  return (
    <div className={styles.blockTheTankSecond}>
      <img
        src={
          "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/mask_group.png"
        }
      />
    </div>
  );
};

const StraightPipes = ({ he, tp, bt }) => {
  return (
    <div
      className={styles.containerStraightPipes}
      style={{ height: he, top: tp, bottom: bt }}
    ></div>
  );
};

const StraightPipesFooter = ({ he }) => {
  return (
    <div
      className={styles.containerStraightPipesFooter}
      style={{ height: he }}
    ></div>
  );
};

const StraightPipesSecondFotter = ({ he, bt }) => {
  return (
    <div
      className={styles.containerStraightPipesSecondFotter}
      style={{ height: he, bottom: bt }}
    ></div>
  );
};

const StraightPipesSecond = ({ he, bt }) => {
  return (
    <div
      className={styles.containerStraightPipesSecond}
      style={{ height: he, bottom: bt }}
    ></div>
  );
};

const ZigzagPipes = () => {
  return (
    <div className={styles.blockZigzag}>
      <img src="https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/vector_281.svg" />
    </div>
  );
};

const TheCanister = () => {
  return (
    <div className={styles.blockTheCanister}>
      <img
        className={styles.imgTheCanister}
        src="https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/mask_group_(1).png"
      />
    </div>
  );
};

const DoublePipesHeader = () => {
  return (
    <div className={styles.blockDoublePipesHeaderS}>
      <div className={styles.containerStraightPipesDouble} />
      <div className={styles.blockDoublePipesHeaderZigZag}>
        <img src="https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/vector_281.svg" />
      </div>
      <div className={styles.blockDoublePipesHeaderZigZagMini}>
        <img src="https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/vectorw106.png" />
      </div>
      {/* <div className={styles.blockHeaderZigZag}>
        <img src="https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/vectorzigzag281.png" />
        <div className={styles.containerStraightPipesDouble} />
      </div> */}
    </div>
  );
};

const DoublePipesFooter = () => {
  return (
    <div className={styles.blockDoublePipes}>
      <div className={styles.containerStraightPipesDouble} />
      <div className={styles.containerStraightPipesDoubleSec} />
    </div>
  );
};
const DoublePipesMain = () => {
  return (
    <div className={styles.blockDoublePipesMain}>
      <div className={styles.containerStraightPipesDouble} />
      <div className={styles.containerStraightPipesDoubleSec} />
    </div>
  );
};

const DoubleTheTank = () => {
  return (
    <div className={styles.blockDubTheTank}>
      <img
        className={styles.theTankMaxH}
        src={
          "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/mask_group.png"
        }
      />
      <img
        className={styles.theTankMaxH}
        src={
          "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/mask_group.png"
        }
      />
      <img
        src={
          "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/mask_group238.png"
        }
      />
      <img
        src={
          "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/mask_group238.png"
        }
      />
    </div>
  );
};

const DoubleTheTankAdptiveSize = () => {
  return (
    <div className={styles.blockDubTheTank}>
      <div className={styles.blockSizeTheTank}>
        <img
          src={
            "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/mask_group.png"
          }
        />
      </div>
      <div className={styles.blockSizeTheTank}>
        <img
          src={
            "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/mask_group.png"
          }
        />
      </div>
    </div>
  );
};

const PcPipes = () => {
  return (
    <div className={styles.blockPipesAll}>
      <img />
    </div>
  );
};

const CornerIcon = () => {
  return (
    <div className={styles.blockCornerIcon}>
      <img />
    </div>
  );
};

const CornerIconOnSlider = () => {
  return (
    <div className={styles.blockCornerIconOnslider}>
      <img />
    </div>
  );
};

export {
  CornerIcon,
  CornerIconOnSlider,
  BlockTheTank,
  StraightPipes,
  BlockTheTankSecond,
  StraightPipesSecond,
  ZigzagPipes,
  TheCanister,
  StraightPipesFooter,
  StraightPipesSecondFotter,
  DoublePipesFooter,
  DoublePipesMain,
  DoublePipesHeader,
  DoubleTheTank,
  DoubleTheTankAdptiveSize,
  PcPipes,
};
